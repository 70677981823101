
//action types

export const SALES_TRANSFER = "sales"
export const PROPERTY_TRANSFER= "property"

//mutation types
export const SET_SALES_TRANSFER = "setSalesTransfer"
export const SET_PROPERTY_TRANSFER = "setPropertyTransfer"


const state={
    sales:[],
    property:[]
}

const getters = {
    getSalesTransfer(){
        return state.sales
    },
    getPropertyTransfer(){
        return state.property
    }
}

const actions = {
     [SALES_TRANSFER](context,payload){
          context.commit(SET_SALES_TRANSFER,payload)
    },
     [PROPERTY_TRANSFER](context,payload){
         context.commit(SET_PROPERTY_TRANSFER,payload)
    }
}
const mutations = {
    [SET_SALES_TRANSFER](state,payload){
        state.sales = payload
    },
    [SET_PROPERTY_TRANSFER](state,payload){
        state.property = payload
    }
}

export default {
    state,
    actions,
    mutations,
    getters,
  };