'use strict';

var DPLayoutAsideToggle = (function () {
  // Private properties
  var _body;
  var _element;
  var _toggleObject;

  // Initialize
  var _init = function () {
    _toggleObject = new DPToggle(_element, {
      target: _body,
      targetState: 'aside-minimize',
      toggleState: 'active',
    });

    let _icon = DPUtil.getById('dp_aside_toggle_icon');

    _toggleObject.on('toggle', function (toggle) {
      // Update sticky card
      if (typeof DPLayoutStickyCard !== 'undefined') {
        DPLayoutStickyCard.update();
      }

      // Pause header menu dropdowns
      if (typeof DPLayoutHeaderMenu !== 'undefined') {
        DPLayoutHeaderMenu.pauseDropdownHover(800);
      }

      // Pause aside menu dropdowns
      if (typeof DPLayoutAsideMenu !== 'undefined') {
        DPLayoutAsideMenu.pauseDropdownHover(800);
      }

      // Remember state in cookie
      DPCookie.setCookie('dp_aside_toggle_state', toggle.getState());
      // to set default minimized left aside use this cookie value in your
      // server side code and add "kt-primary--minimize aside-minimize" classes to
      // the body tag in order to initialize the minimized left aside mode during page loading.

      // DPUtil.addClass(
      //   _icon,
      //   toggle.getState() == 'on' ? 'fa-chevron-right' : 'fa-chevron-left'
      // );
      // DPUtil.removeClass(
      //   _icon,
      //   toggle.getState() == 'off' ? 'fa-chevron-right' : 'fa-chevron-left'
      // );
    });

    _toggleObject.on('beforeToggle', function (toggle) {
      if (
        DPUtil.hasClass(_body, 'aside-minimize') === false &&
        DPUtil.hasClass(_body, 'aside-minimize-hover')
      ) {
        DPUtil.removeClass(_body, 'aside-minimize-hover');
      }
    });
  };

  // Public methods
  return {
    init: function (id) {
      _element = DPUtil.getById(id);
      _body = DPUtil.getBody();

      if (!_element) {
        return;
      }

      // Initialize
      _init();
    },

    getElement: function () {
      return _element;
    },

    getToggle: function () {
      return _toggleObject;
    },

    onToggle: function (handler) {
      if (typeof _toggleObject.element !== 'undefined') {
        _toggleObject.on('toggle', handler);
      }
    },
  };
})();

export default DPLayoutAsideToggle;
