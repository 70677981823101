import {
  LOGOUT,
  GET_MY_USER,
  VERIFY_AUTH,
} from '@/core/services/store/auth.module';
import JwtService from '@/core/services/jwt.service';
import jwt from 'jwt-decode';


export default function verifyUser({ next, store, router }) {
  const myUser = JwtService.getCurrentUserToken();
  const decodeToken = jwt(myUser)  ? jwt(myUser) :""
  if (myUser) {
    if(decodeToken && decodeToken.user_group){
      store.dispatch(GET_MY_USER,myUser)
    }else{
      console.log("calisti 2")
      store.dispatch(LOGOUT).then(() => (window.location = '/giris'));
    }
  } else {
    store.dispatch(LOGOUT).then(() => (window.location = '/giris'));
  }

  return next();
}
