import ApiService from '@/core/services/api.service';

// action types

export const PROPERTIES = "properties"
export const ADD_PROPERTY ="postProperty"
export const PROPERTY = "property"
// mutation types

export const SET_PROPERTIES = "setProperties"
export const SET_NEW_PROPERTY ="setNewProperty"
export const SET_PROPERTY = "setProperty"

const state = {
    errors:null,
    properties : [],
    property:[]
}

const getters = {
    getProperties(state){
        return state.properties
    },
    getProperty(state){
        return state.property
    }
}

const actions = {
    [PROPERTIES](context,payload){
        context.commit(SET_PROPERTIES,payload)
    },
    [ADD_PROPERTY](context,payload){
        context.commit(SET_NEW_PROPERTY,payload)
    },
    async [PROPERTY](context,payload){
        const response = await ApiService.get(`property/property?property_id=${payload.property_id}&user_token=${payload.user_token}`)
        context.commit(SET_PROPERTY,response.data.response.data)
        return response.data.response.data
    }
}
const mutations = {
    [SET_PROPERTIES](state,payload){
        state.properties = payload
    },
    [SET_PROPERTY](state,payload){
        state.property = payload
    }
}
export default {
    state,
    actions,
    mutations,
    getters,
};