// Keenthemes plugins
import DPUtil from "@/assets/js/components/util.js";
window.DPUtil = DPUtil;

import DPCard from "@/assets/js/components/card.js";
window.DPCard = DPCard;

import DPCookie from "@/assets/js/components/cookie.js";
window.DPCookie = DPCookie;

import DPDialog from "@/assets/js/components/dialog.js";
window.DPDialog = DPDialog;

import DPHeader from "@/assets/js/components/header.js";
window.DPHeader = DPHeader;

import DPImageInput from "@/assets/js/components/image-input.js";
window.DPImageInput = DPImageInput;

import DPMenu from "@/assets/js/components/menu.js";
window.DPMenu = DPMenu;

import DPOffcanvas from "@/assets/js/components/offcanvas.js";
window.DPOffcanvas = DPOffcanvas;

import DPScrolltop from "@/assets/js/components/scrolltop.js";
window.DPScrolltop = DPScrolltop;

import DPToggle from "@/assets/js/components/toggle.js";
window.DPToggle = DPToggle;

import DPWizard from "@/assets/js/components/wizard.js";
window.DPWizard = DPWizard;

import DPLayoutAside from "@/assets/js/layout/base/aside.js";
window.DPLayoutAside = DPLayoutAside;

import DPLayoutAsideMenu from "@/assets/js/layout/base/aside-menu.js";
window.DPLayoutAsideMenu = DPLayoutAsideMenu;

import DPLayoutAsideToggle from "@/assets/js/layout/base/aside-toggle.js";
window.DPLayoutAsideToggle = DPLayoutAsideToggle;

import DPLayoutContent from "@/assets/js/layout/base/content.js";
window.DPLayoutContent = DPLayoutContent;

import DPLayoutFooter from "@/assets/js/layout/base/footer.js";
window.DPLayoutFooter = DPLayoutFooter;

import DPLayoutHeader from "@/assets/js/layout/base/header.js";
window.DPLayoutHeader = DPLayoutHeader;

import DPLayoutHeaderMenu from "@/assets/js/layout/base/header-menu.js";
window.DPLayoutHeaderMenu = DPLayoutHeaderMenu;

import DPLayoutHeaderTopbar from "@/assets/js/layout/base/header-topbar.js";
window.DPLayoutHeaderTopbar = DPLayoutHeaderTopbar;

import DPLayoutStickyCard from "@/assets/js/layout/base/sticky-card.js";
window.DPLayoutStickyCard = DPLayoutStickyCard;

import DPLayoutStretchedCard from "@/assets/js/layout/base/stretched-card.js";
window.DPLayoutStretchedCard = DPLayoutStretchedCard;

import DPLayoutSubheader from "@/assets/js/layout/base/subheader.js";
window.DPLayoutSubheader = DPLayoutSubheader;

// Metronic layout extended js
import DPLayoutChat from "@/assets/js/layout/extended/chat.js";
window.DPLayoutChat = DPLayoutChat;

import DPLayoutExamples from "@/assets/js/layout/extended/examples.js";
window.DPLayoutExamples = DPLayoutExamples;

import DPLayoutQuickActions from "@/assets/js/layout/extended/quick-actions.js";
window.DPLayoutQuickActions = DPLayoutQuickActions;

import DPLayoutQuickCartPanel from "@/assets/js/layout/extended/quick-cart.js";
window.DPLayoutQuickCartPanel = DPLayoutQuickCartPanel;

import DPLayoutQuickNotifications from "@/assets/js/layout/extended/quick-notifications.js";
window.DPLayoutQuickNotifications = DPLayoutQuickNotifications;

import DPLayoutQuickPanel from "@/assets/js/layout/extended/quick-panel.js";
window.DPLayoutQuickPanel = DPLayoutQuickPanel;

import DPLayoutQuickSearch from "@/assets/js/layout/extended/quick-search.js";
window.DPLayoutQuickSearch = DPLayoutQuickSearch;

import DPLayoutQuickUser from "@/assets/js/layout/extended/quick-user.js";
window.DPLayoutQuickUser = DPLayoutQuickUser;

import DPLayoutScrolltop from "@/assets/js/layout/extended/scrolltop.js";
window.DPLayoutScrolltop = DPLayoutScrolltop;

import DPLayoutSearch from "@/assets/js/layout/extended/search.js";
window.DPLayoutSearch = DPLayoutSearch;
