import ApiService from '@/core/services/api.service';


//action types

export const PURPOSE = "purpose"
export const USAGE = "usage"

//mutation types

export const SET_PURPOSE ="setPurpose"
export const SET_USAGE ="setUsage"


const state = {
    purpose : [],
    usage : []
}

const getters = {
    getPurpose(){
        return state.purpose
    },
    getUsage(){
        return state.usage
    }
}

const actions = {
    async [PURPOSE](context,payload){
        try {
            const response =await ApiService.get("admin/purposeofusage?user_token="+payload)
            context.commit(SET_PURPOSE, response.data.response.data);
            return response.data.response.data;
        } catch (error) {
            console.error("Error fetching districts:", error);
            return [];
        }

    },
    async [USAGE](context,payload){
        try {
            let url = "admin/usageattribute?user_token="+payload.user_token
            if(payload.purpose_id) url+= "&purpose_id="+payload.purpose_id
            const response = await ApiService.get(url)
            context.commit(SET_USAGE, response.data.response.data);
            return response.data.response.data;
        } catch (error) {
            console.error("Error fetching districts:", error);
            return [];
        }
        

    }
}

const mutations = {
    [SET_PURPOSE](state,payload){
        state.purpose = payload
    },
    [SET_USAGE](state,payload){
        state.usage = payload
    }
}

export default {
    state,
    actions,
    mutations,
    getters,
  };
  