import ApiService from '@/core/services/api.service';


// action types
export const DECLARATION = "declaration"

// mutation types

export const SET_DECLARATION = "setDeclaration"

const state= {
    declarations:[]
}

const getters = {
    getDeclaration(){
        return state.declarations
    }
}

const actions = {
    async [DECLARATION](context,payload){
        context.commit(SET_DECLARATION,payload)
    }
}

const mutations = {
    [SET_DECLARATION](state,payload){
        state.declarations = payload
    }
}


export default {
    state,
    actions,
    mutations,
    getters,
  };