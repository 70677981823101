import ApiService from '@/core/services/api.service';


//action types

export const ADVERTS = "adverts"

//mutation types

export const SET_ADVERTS = "setAdverts"


const state = {
    adverts : []
}

const getters ={
    getAdverts(){
        return state.adverts
    }
}

const actions = {
    async [ADVERTS](context,payload){
        try {
            const response =await ApiService.get("advert/advert?user_token="+payload.myUser + payload.query)
            context.commit(SET_ADVERTS, response.data.response.data);
            return response.data.response.data;

        } catch (error) {
            console.error("Error fetching districts:", error);
            return [];
        }
    }
}

const mutations = {
    [SET_ADVERTS](state,payload){
        state.adverts = payload
    }
}

export default {
    state,
    actions,
    mutations,
    getters,
  };