import Vue from 'vue';

import ApiService from '@/core/services/api.service';
Vue.prototype.$ApiService = ApiService;

import EventBus from '@/event-bus';
Vue.prototype.$EventBus = EventBus;

import JwtService from '@/core/services/jwt.service';
Vue.prototype.$JwtService = JwtService;

import spinnerLoader from '@/core/plugins/spinnerLoader.js';
Vue.prototype.$spinnerLoader = spinnerLoader;

import customFunctions from '@/core/plugins/customFunctions.js';
Vue.prototype.$customFunctions = customFunctions;

import dbFunctions from '@/core/plugins/dbFunctions.js';
Vue.prototype.$dbFunctions = dbFunctions;

import generateToast from '@/core/plugins/generateToast.js';
Vue.prototype.$generateToast = generateToast;

import generateNotification from '@/core/plugins/generateNotification.js';
Vue.prototype.$generateNotification = generateNotification;
