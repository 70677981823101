import ApiService from '@/core/services/api.service';

// ACTION TYPES

export const CUSTOMERS = "customers"

//mutations types
export const SET_CUSTOMER = "setCustomers"


const state={
    customers: []
}

const getters = {
    getCustomers(){
        return state.customers
    }
}

const actions = {
    async [CUSTOMERS](context,payload){
        try {
            const response = await ApiService.get("admin/customers?user_token=" + payload.myUser + payload.query)
            context.commit(SET_CUSTOMER,response.data.response.data)
            return response.data.response.data
        } catch (error) {
            console.error("Error fetching sellers:", err);
            return [];
        }
    }
}
const mutations = {
    [SET_CUSTOMER](state,payload){
        state.customers = payload
    }
}
export default {
    state,
    actions,
    mutations,
    getters,
  };