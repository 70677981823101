"use strict";

var DPLayoutQuickNotifications = function () {
    // Private properties
    var _element;
    var _offcanvasObject;

    // Private functions
    var _init = function () {
        var header = DPUtil.find(_element, '.offcanvas-header');
        var content = DPUtil.find(_element, '.offcanvas-content');

        _offcanvasObject = new DPOffcanvas(_element, {
            overlay: true,
            baseClass: 'offcanvas',
            placement: 'right',
            closeBy: 'dp_quick_notifications_close',
            toggleBy: 'dp_quick_notifications_toggle'
        });

        DPUtil.scrollInit(content, {
            disableForMobile: true,
            resetHeightOnDestroy: true,
            handleWindowResize: true,
            height: function () {
                var height = parseInt(DPUtil.getViewPort().height);

                if (header) {
                    height = height - parseInt(DPUtil.actualHeight(header));
                    height = height - parseInt(DPUtil.css(header, 'marginTop'));
                    height = height - parseInt(DPUtil.css(header, 'marginBottom'));
                }

                if (content) {
                    height = height - parseInt(DPUtil.css(content, 'marginTop'));
                    height = height - parseInt(DPUtil.css(content, 'marginBottom'));
                }

                height = height - parseInt(DPUtil.css(_element, 'paddingTop'));
                height = height - parseInt(DPUtil.css(_element, 'paddingBottom'));

                height = height - 2;

                return height;
            }
        });
    }

    // Public methods
    return {
        init: function (id) {
            _element = DPUtil.getById(id);

            if (!_element) {
                return;
            }

            // Initialize
            _init();
        },

        getElement: function () {
            return _element;
        }
    };
}();



export default DPLayoutQuickNotifications;