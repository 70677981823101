import ApiService from '@/core/services/api.service';

// action types

export const NOTES = "notes"
export const POST_NOTES ="postNotes"
export const PROPERTY_NOTES ="propertyNotes"
// mutation types

export const SET_NOTES = "setNotes"
export const SET_NEW_NOTES ="setNewNotes"
export const SET_PROPERTY_NOTES = "setPropertyNotes"


const state = {
    errors:null,
    notes : [],
    propertyNotes:[]
}

const getters = {
    getNotes(state){
        return state.notes
    },
    getPropertyNotes(state){
        return state.propertyNotes
    }
}

const actions = {
    [NOTES](context,payload){
        context.commit(SET_NOTES,payload)
    },
    async [PROPERTY_NOTES](context,payload){
        try {
            const response =await ApiService.get("property/note?user_token="+payload.myUser+"&property_id="+payload.property_id)
            context.commit(SET_PROPERTY_NOTES,response.data.response.data)
            return response.data.response.data
        } catch (error) {
            console.error("Error fetching districts:", error);
            return [];

        }
    },
    async [POST_NOTES](context,payload){
        try {
            const response = await ApiService.post("property/note",payload)
            return response.data.status
        } catch (error) {
            console.error(error)
            return false
        }
    }
}
const mutations = {
    [SET_NOTES](state,payload){
        state.notes = payload
    },
    [SET_PROPERTY_NOTES](state,payload){
        state.propertyNotes = payload
    }
}
export default {
    state,
    actions,
    mutations,
    getters,
};