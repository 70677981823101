import ApiService from '@/core/services/api.service';

//ACTION TYPES

export const COMPANY  = "company"

// MUTATION TYPES

export const SET_COMPANY = "setCompany"


const state = {
    company:[]
}

const getters = {
    getCompany(){
        return this.company
    }
}

const actions = {
    async [COMPANY](context,payload){
        try {
            const response = await ApiService.get("company/company?user_token="+payload)
            context.commit(SET_COMPANY,response.data.response.data)
            return response.data.response.data
        } catch (error) {
            console.error("ERROR:",error)
            return []
        }
    }
}

const mutations = {
    [SET_COMPANY](state,payload){
        state.company = payload
    }
}

export default {
    state,
    actions,
    mutations,
    getters,
  };