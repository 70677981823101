import Vue from 'vue';
import Vuex from 'vuex';

import auth from './auth.module';
import htmlClass from './htmlclass.module';
import config from './config.module';
import breadcrumbs from './breadcrumbs.module';
import usage from "./usage.module"
import dataEntrySchedule from './dataEntrySchedule.module';
import counties from './counties.module';
import notes from "./property/notes.module"

import property from "./property/property.module"
import seller from "./seller/seller.module"
import declaration from "./declaration/declaration.module"
import transfer from "./transfer/transfer.module"
import customers from "./customers/customers.module"
import features from './features/features.module';
import adverts from "./adverts/adverts.module"
import company from './company.module';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    htmlClass,
    config,
    breadcrumbs,
    dataEntrySchedule,
    notes,
    property,
    counties,
    seller,
    usage,
    declaration,
    transfer,
    customers,
    features,
    adverts,
    company
  },
});
