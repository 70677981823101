import ApiService from '@/core/services/api.service';

//action types
export const FEATURES = "features"
export const FEATURES_CATEGORY ="featuresCategory"
//mutation types
export const SET_FEATURES = "setFeatures"
export const SET_FEATURES_CATEGORY = "setFeaturesCategory"


const state = {
    features : [],
    featuresCategory : []
}

const getters = {
    getFeatures(){
        return state.features
    },
    getFeaturesCategory (){
        return state.featuresCategory
    }
}

const actions = {
    async [FEATURES](context,payload){
        try {
            const response = await ApiService.get("admin/features?user_token="+payload.myUser + payload.query)
            context.commit(SET_FEATURES,response.data.response.data)
            return response.data.response.data
        } catch (error) {
            console.error("Error fetching features:", error);
            return [];
        }
    },
    async [FEATURES_CATEGORY](context,payload){
        try {
            const response = await ApiService.get("admin/featurescategories?user_token="+payload.myUser + payload.query)
            context.commit(SET_FEATURES_CATEGORY,response.data.response.data)
            return response.data.response.data
        } catch (error) {
            console.error("Error fetching features:", error);
            return [];
        }
    }
}

const mutations = {
    [SET_FEATURES](state,payload){
        state.features = payload
    },
    [SET_FEATURES_CATEGORY](state,payload){
        state.featuresCategory = payload
    }
}


export default {
    state,
    getters,
    actions,
    mutations
}