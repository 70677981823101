import ApiService from '@/core/services/api.service';

// action types
export const GET_DATAENTRYSCHEDULEISOPEN = 'getDataScheduleIsOpen';

// mutation types
export const SET_DATAENTRYSCHEDULEISOPEN = 'setDataScheduleIsOpen';

const state = {
  dataEntryScheduleIsOpen: null,
};

const getters = {
  dataEntryScheduleIsOpen(state) {
    return state.dataEntryScheduleIsOpen;
  },
};

const actions = {
  async [GET_DATAENTRYSCHEDULEISOPEN](context) {
    let { data } = await ApiService.get(`dataEntrySchedule/isOpen`);
    context.commit(SET_DATAENTRYSCHEDULEISOPEN, data.success);
  },
};

const mutations = {
  [SET_DATAENTRYSCHEDULEISOPEN](state, payload) {
    state.dataEntryScheduleIsOpen = payload;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
