"use strict";

var DPLayoutExamples = function () {

    var initDefaultMode = function (element) {
        var elements = element;
        if (typeof elements === 'undefined') {
            elements = document.querySelectorAll('.example:not(.example-compact):not(.example-hover):not(.example-basic)');
        }

        for (var i = 0; i < elements.length; ++i) {
            var example = elements[i];
            var copy = DPUtil.find(example, '.example-copy');

            var clipboard = new ClipboardJS(copy, {
                target: function (trigger) {
                    var example = trigger.closest('.example');
                    var el = DPUtil.find(example, '.example-code .tab-pane.active');

                    if (!el) {
                        el = DPUtil.find(example, '.example-code');
                    }

                    return el;
                }
            });

            clipboard.on('success', function (e) {
                DPUtil.addClass(e.trigger, 'example-copied');
                e.clearSelection();

                setTimeout(function () {
                    DPUtil.removeClass(e.trigger, 'example-copied');
                }, 2000);
            });
        }
    }

    var initCompactMode = function (element) {
        var example, code, toggle, copy, clipboard;
        var elements = element;
        if (typeof elements === 'undefined') {
            var elements = document.querySelectorAll('.example.example-compact');
        }

        for (var i = 0; i < elements.length; ++i) {
            var example = elements[i];
            var toggle = DPUtil.find(example, '.example-toggle');
            var copy = DPUtil.find(example, '.example-copy');

            // Handle toggle
            DPUtil.addEvent(toggle, 'click', function () {
                var example = this.closest('.example');
                var code = DPUtil.find(example, '.example-code');
                var the = this;

                if (DPUtil.hasClass(this, 'example-toggled')) {
                    DPUtil.slideUp(code, 300, function () {
                        DPUtil.removeClass(the, 'example-toggled');
                        DPUtil.removeClass(code, 'example-code-on');
                        DPUtil.hide(code);
                    });
                } else {
                    DPUtil.addClass(code, 'example-code-on');
                    DPUtil.addClass(this, 'example-toggled');
                    DPUtil.slideDown(code, 300, function () {
                        DPUtil.show(code);
                    });
                }
            });

            // Handle copy
            var clipboard = new ClipboardJS(copy, {
                target: function (trigger) {
                    var example = trigger.closest('.example');
                    var el = DPUtil.find(example, '.example-code .tab-pane.active');

                    if (!el) {
                        el = DPUtil.find(example, '.example-code');
                    }

                    return el;
                }
            });

            clipboard.on('success', function (e) {
                DPUtil.addClass(e.trigger, 'example-copied');
                e.clearSelection();

                setTimeout(function () {
                    DPUtil.removeClass(e.trigger, 'example-copied');
                }, 2000);
            });
        }
    }

    return {
        init: function (element, options) {
            initDefaultMode(element);
            initCompactMode(element);
        }
    };
}();



export default DPLayoutExamples;