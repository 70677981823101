import ApiService from '@/core/services/api.service';


//action types
export const SELLERS = "sellers"

//mutation types

export const SET_SELLERS= "getSellers"



const state = {
    sellers : []
}
const getters = {
    getSellers(){
        return state.sellers
    }
}


const actions={
    async [SELLERS](context, payload) {
        try {
            const response = await ApiService.get("admin/seller?user_token=" + payload.myUser + payload.query);
            context.commit(SET_SELLERS, response.data.response.data);
            return response.data.response.data;
        } catch (err) {
            console.error("Error fetching sellers:", err);
            return [];
        }
    }    
}

const mutations={
    [SET_SELLERS](state,payload){
        state.sellers = payload
    }
}

export default {
    state,
    actions,
    mutations,
    getters,
  };
  