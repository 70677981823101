const spinnerLoader = {
  addSpinnerLoader(refName) {
    refName.classList.add('spinner', 'spinner-light', 'spinner-right')
  },
  removeSpinnerLoader(refName) {
    refName.classList.remove('spinner', 'spinner-light', 'spinner-right')
  },
}

export default spinnerLoader
